<template>
    <div class="customize-wrapper">
        <div class="content">
            <el-breadcrumb separator=">">
                <el-breadcrumb-item>公众号/小程序</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/officialAccountsOrApplet' }">授权公众号</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/publicAccountManagement', query: { appid: appid, nickName: nickName, headImg: headImg } }">公众号管理</el-breadcrumb-item>
                <el-breadcrumb-item>自定义菜单栏</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="menu-wrap">
                <div class="menu-left">
                    <div class="menu-title">{{nickName}}</div>

                    <div class="menu-bot">
                        <div class="menu-box">
                            <div class="menu-list">
                                <div class="menu-item" v-for="(item, index) in menu.button" :key="index" :style="{ width: buttonWidth }">
                                    <div class="itemName" :class="{current:menuIndex[index].isActive}"
                                     @click.stop="selectedMenu(item, index, 1)">
                                        {{item.name}}
                                    </div>

                                    <div class="sub-menu-list-wrap" v-if="selectedMenuIndex == index">
                                        <div class="sub-menu-list" v-for="(subList, indexs) in item.sub_button.list" :key="indexs"
                                         :class="{current:indexs == subBtnIndex[index].num}"
                                         @click.stop="selectedSubMenu(subList, index, indexs, 2)">
                                            <div class="sub-menu-item">{{subList.name}}</div>

                                            <i class="menu-arrow arrow_out"></i>
                                            <i class="menu-arrow arrow_in"></i>
                                        </div>

                                        <div class="sub-menu-list" v-if="item.sub_button.length < 5 || item.sub_button.list.length < 5">
                                            <div class="menu-sub-addBtn el-icon-plus" @click="addMenu(2, index)"></div>

                                            <i class="menu-arrow arrow_out"></i>
                                            <i class="menu-arrow arrow_in"></i>
                                        </div>
                                    </div>

                                </div>

                                <div class="menu-addBtn el-icon-plus" v-if="menu.button.length == 0" @click="addMenu(1, 0)"></div>
                                <div class="menu-addBtn el-icon-plus" v-if="menu.button.length == 1" @click="addMenu(1, 1)"></div>
                                <div class="menu-addBtn el-icon-plus" v-if="menu.button.length == 2" @click="addMenu(1, 2)"></div>
                            </div>

                        </div>

                    </div>
                </div>

                <!-- 主菜单及子菜单 -->
                <div class="menu-right">
                    <div class="menu-detail" v-if="operationShow">
                        <div class="menu-t">
                            <div class="menu-name">{{rightTitle}}</div>
                            <div class="menu-delBtn" @click="delMenu">删除菜单</div>
                        </div>

                        <div class="menu-input-group">
                            <div class="menu-input-name">菜单名称</div>
                            <div class="menu-input-right">
                                <div class="menu-input">
                                    <el-input type="text" v-model="rightTitle" placeholder="请输入菜单名称" clearable
                                     @input="rightInput" @blur="rightBlur" show-word-limit maxlength="16"  />
                                </div>
                                <div class="warning" v-if="warning">字数超过上限</div>
                                <div class="menu-inp-title">仅支持中英文和数字，字数不超过8个汉字或16个字母</div>
                            </div>
                        </div>
                        
                        <template v-if="urlShow">
                            <div class="menu-cont">
                                <div class="menu-cont-name">菜单内容</div>

                                <div class="menu-cont-right">
                                    <div class="choose-box">
                                        <div class="choose-item" v-for="(item, index) in contData" :key="index"
                                         @click="chooseCont(index)">
                                            <div class="choose-icon" v-if="contIndex !== index">
                                                <img src="../../assets/ellipse.png" />
                                            </div>
                                            <div class="choose-icon" v-if="contIndex == index">
                                                <img src="../../assets/ellipse-hover.png" />
                                            </div>
                                            <div class="choose-text">{{item.texts}}</div>
                                        </div>
                                    </div>

                                    <div class="cont-wrap">
                                        <div class="ueditor" v-if="contIndex == 0">
                                            <el-input type="textarea" v-model="m_key" ref="m_key" maxlength="300"
                                             show-word-limit @blur="keyBlur" clearable resize="none"></el-input>
                                            <!-- <vue-ueditor-wrap v-model="m_key" @ready="ready" :config="editorConfig"></vue-ueditor-wrap> -->
                                        </div>

                                        <div class="refresh-item" v-if="contIndex == 1">
                                            <el-input type="text" v-model="m_url" ref="urlFocus" placeholder="请输入链接"
                                             @blur="blurMenu" clearable>
                                                <template #prepend>页面地址：</template>
                                            </el-input>
                                        </div>

                                        <div class="refresh-item" v-if="contIndex == 2">
                                            <div class="refresh-inp">
                                                <div class="refresh-appid">
                                                    <div class="appid-name">小程序AppID:</div>

                                                    <el-select v-model="m_appid"
                                                     filterable allow-create default-first-option
                                                     placeholder="请输入小程序AppID或绑定后快捷选择"
                                                     @change="selectMarket">
                                                        <el-option v-for="item in wxOptions" :key="item.nickname"
                                                         :label="item.nickname" :value="item.appid">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </div>
                                            <div class="refresh-inp">
                                                <el-input type="text" v-model="m_pagepath" ref="m_pagepath" placeholder="请输入跳转小程序页面 示例：pages/index/index"
                                                 @blur="pagepathBlur" clearable>
                                                    <template #prepend>小程序路径(必填):</template>
                                                </el-input>
                                            </div>
                                            <div class="refresh-inp">
                                                <el-input type="text" v-model="standby_url" ref="standby_url" placeholder="旧版微信客户端无法支持小程序，用户点击菜单时，将打开备用网页"
                                                 @blur="standbyUrlBlur" clearable>
                                                    <template #prepend>备用页面(必填):</template>
                                                </el-input>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div class="menu-no" v-else>点击左侧菜单进行编辑操作</div>
                </div>

            </div>
            
            <div class="btnsItem" v-if="menu.button.length > 0">
                <el-button type="danger" :disabled="disabled" @click="saveItem">保存并发布</el-button>
                
                <el-button @click="cancel">取消</el-button>
            </div>

        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    import global from '@/assets/js/global';
    import VueUeditorWrap from 'vue-ueditor-wrap';

    export default {
        components: {
            VueUeditorWrap
        },
        data() {
            return {
                appid: this.$route.query.appid,
                nickName: this.$route.query.nickName,
                headImg: this.$route.query.headImg,
                menuName: '', // 菜单名称
                contData: [
                    {texts: '文本内容'},
                    {texts: '跳转网页'},
                    {texts: '跳转小程序'}
                ],
                contIndex: 0,
                contIndexSub: 0,

                linkName: '', // 页面地址
                appId: '',
                standby: '',
                menu: {
                    button: []
                },
                selectedMenuIndex: '', // 当前选中菜单索引
                selectedSubMenuIndex: '', // 当前选中子菜单索引
                wxOptions: [],
                appId: '', // 小程序appid
                operationShow: true,
                disabled: false,
                levnlIndex: 1,

                menuIndex: [
                    {isActive:false},
                    {isActive:false},
                    {isActive:false}
                ],
                subBtnIndex: [
                    {num:-1},
                    {num:-1},
                    {num:-1}
                ],
                rightTitle: '',
                m_url: '',
                m_appid: '',
                m_pagepath: '',
                m_key: '',
                standby_url: '',

                urlShow: true,

                editorConfig: {
                    UEDITOR_HOME_URL: '/UEditor/',
                    serverUrl: '',
                    // 初始容器高度
                    initialFrameHeight: 200,
                    // 初始容器宽度
                    initialFrameWidth: '100%',
                    toolbars: [],
                    elementPathEnabled : false,
                    // 关闭字数统计
	                wordCount: false,
                    enableAutoSave: false
                },
                ue: '',
                warning: false,
                disabled: false

            }
        },
        created() {
            
        },
        computed: {
            buttonWidth() {
                return this.menu.button.length == 3 ? '33.3%' : 100 / (this.menu.button.length + 1) + '%';
            }
        },
        mounted() {
            this.getMenu();
            this.getWxamplink();
        },
        methods: {
            // 关联的小程序appid列表
            getWxamplink() {
                axios.get(global.wechatURL + "third/wxamplinkget", {
                    params: {
                        appid: this.appid
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        var data = res.data.data;
                        this.wxOptions = data;
                    } else {
                        this.$message({ showClose: true, message: res.data.message, type: 'error'})
                    }
                });
            },
            selectMarket(e) {
                if(this.levnlIndex == 1) {
                    this.menu.button[this.selectedMenuIndex].appid = e;
                } else if(this.levnlIndex == 2) {
                    this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].appid = e;
                }
            },
            chooseCont(index) {
                this.contIndex = index;
                if(index == 0 && this.levnlIndex == 1) {
                    this.menu.button[this.selectedMenuIndex].type = 'click';
                    this.$nextTick(() => {
                        this.$refs.m_key.focus();
                    })
                } else if(index == 1 && this.levnlIndex == 1) {
                    this.menu.button[this.selectedMenuIndex].type = 'view';
                    this.$nextTick(() => {
                        this.$refs.urlFocus.focus();
                    })
                } else if(index == 2 && this.levnlIndex == 1) {
                    this.menu.button[this.selectedMenuIndex].type = 'miniprogram';
                    this.$nextTick(() => {
                        this.$refs.m_pagepath.focus();
                    })
                }
                if(index == 0 && this.levnlIndex == 2) {
                    this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].type = 'click';
                    this.$nextTick(() => {
                        this.$refs.m_key.focus();
                    })
                } else if(index == 1 && this.levnlIndex == 2) {
                    this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].type = 'view';
                    this.$nextTick(() => {
                        this.$refs.urlFocus.focus();
                    })
                } else if(index == 2 && this.levnlIndex == 2) {
                    this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].type = 'miniprogram';
                    this.$nextTick(() => {
                        this.$refs.m_pagepath.focus();
                    })
                }
            },
            // 
            getMenu() {
                axios.get(global.wechatURL + "third/set_menu2", {
                    params: {
                        appid: this.appid
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        var menuList = res.data.data.selfmenu_info;
                        menuList.button.forEach((item, index) => {
                            if (!item.hasOwnProperty('sub_button')) {
                                this.$set(item, 'sub_button', {list: []})
                            }
                            if(item.type == 'click') {
                                var key_split = item.key.split('@@@');
                                if(key_split[0] == 'text') {
                                    item.key = key_split[1];
                                } else {
                                    item.key = item.key;
                                }
                            }
                            if(item.sub_button) {
                                item.sub_button.list.forEach((items, indexs) => {
                                    if(items.type == 'click') {
                                        var key_split = items.key.split('@@@');
                                        if(key_split[0] == 'text') {
                                            items.key = key_split[1];
                                        } else {
                                            items.key = items.key;
                                        }
                                    }
                                })
                            }
                        });
                        
                        this.menu = menuList;
                        this.selectedMenu(menuList.button[0], 0, 1);
                        if(this.menu.button[this.selectedMenuIndex].sub_button.list.length > 0) {
                            this.loadingSelectedSubMenu(menuList.button[0].sub_button.list[0], 0, 0, 2);
                        }
                        // console.log(this.menu)
                    } else {
                        this.$message({ showClose: true, message: res.data.message, type: 'error'})
                    }
                });
            },

            //选中主菜单
            selectedMenu(item, i, levnl) {
                this.levnlIndex = levnl;

                this.selectedSubMenuIndex = '';
                this.selectedMenuIndex = i;
                this.m_url = '';
                this.standby_url = '';

                this.urlWindow(i);

                for ( let i = 0; i < this.menu.button.length; i++ ) {
                    this.subBtnIndex[i].num = -1; // 重置所有子菜单选中状态
                    this.menuIndex[i].isActive = false; // 重置一级菜单
                }
                this.menuIndex[i].isActive = true;

                if(item.type == 'click') {
                    this.contIndex = 0;
                    if(this.menu.button[this.selectedMenuIndex].sub_button.list.length == 0) {
                        this.$nextTick(() => {
                            this.$refs.m_key.focus();
                        })
                    }
                } else if(item.type == 'view') {
                    this.contIndex = 1;
                    if(this.menu.button[this.selectedMenuIndex].sub_button.list.length == 0) {
                        this.$nextTick(() => {
                            this.$refs.urlFocus.focus();
                        })
                    }
                } else if(item.type == 'miniprogram') {
                    this.contIndex = 2;
                    if(this.menu.button[this.selectedMenuIndex].sub_button.list.length == 0) {
                        this.$nextTick(() => {
                            this.$refs.m_pagepath.focus();
                        })
                    }
                }

                let menu_name = item.name;
                let menu_url = item.url;
                let menu_appid = item.appid;
                let menu_pagepath = item.pagepath;
                let menu_key = item.key;
                this.getInfo(menu_name, menu_url, menu_appid, menu_pagepath, menu_key, item.type);
                this.operationShow = true;
            },
            //选中子菜单
            selectedSubMenu(items, index, i, levnl) {
                this.levnlIndex = levnl;
                this.selectedSubMenuIndex = i;
                this.m_url = '';
                this.standby_url = '';

                this.urlWindow(i, true);

                for ( let i = 0; i < this.menu.button.length; i++ ) {
                    this.subBtnIndex[i].num = -1; // 重置所有子菜单选中状态
                    this.menuIndex[i].isActive = false; // 重置一级菜单
                }
                this.subBtnIndex[index].num = this.selectedSubMenuIndex;

                if(items.type == 'click') {
                    this.contIndex = 0;
                    this.$nextTick(() => {
                        this.$refs.m_key.focus();
                    })
                } else if(items.type == 'view') {
                    this.contIndex = 1;
                    this.$nextTick(() => {
                        this.$refs.urlFocus.focus();
                    })
                } else if(items.type == 'miniprogram') {
                    this.contIndex = 2;
                    this.$nextTick(() => {
                        // this.$refs.standby_url.focus();
                        this.$refs.m_pagepath.focus();
                    })
                }

                let sub_name = items.name;
                let sub_url = items.url;
                let sub_appid = items.appid;
                let sub_pagepath = items.pagepath;
                let sub_key = items.key;
                this.getInfo(sub_name, sub_url, sub_appid, sub_pagepath, sub_key, items.type);

                this.operationShow = true;
            },
            loadingSelectedSubMenu(items, index, i, levnl) {
                this.levnlIndex = levnl;
                this.selectedSubMenuIndex = i;
                this.m_url = '';
                this.standby_url = '';

                this.urlShow = true;

                for ( let i = 0; i < this.menu.button.length; i++ ) {
                    this.subBtnIndex[i].num = -1; // 重置所有子菜单选中状态
                    this.menuIndex[i].isActive = false; // 重置一级菜单
                }
                this.subBtnIndex[index].num = this.selectedSubMenuIndex;

                if(items.type == 'click') {
                    this.contIndex = 0;
                } else if(items.type == 'view') {
                    this.contIndex = 1;
                } else if(items.type == 'miniprogram') {
                    this.contIndex = 2;
                }

                let sub_name = items.name;
                let sub_url = items.url;
                let sub_appid = items.appid;
                let sub_pagepath = items.pagepath;
                let sub_key = items.key;
                this.getInfo(sub_name, sub_url, sub_appid, sub_pagepath, sub_key, items.type);

                this.operationShow = true;
            },
            // 显示右侧菜单内容
            urlWindow(index, subIndex) {
                if(!subIndex && this.menu.button[index].sub_button.list.length > 0) {
                    this.urlShow = false;
                } else {
                    this.urlShow = true;
                }
            },
            // 添加菜单
            async addMenu(level, index) {
                this.levnlIndex = level;
                this.selectedMenuIndex = index;
                this.m_url = '';
                this.standby_url = '';

                if (level == 1 && this.menu.button.length < 3) {
                    this.menu.button.push({
                        type: 'click',
                        name: '菜单名称',
                        url: '',
                        appid: '',
                        pagepath: '',
                        key: '',
                        sub_button: {
                            list: []
                        }
                    });

                    this.urlWindow(index);
                    this.operationShow = true;
                    
                    for ( let i = 0; i < this.menu.button.length; i++ ) {
                        this.subBtnIndex[i].num = -1; // 重置所有子菜单选中状态
                        this.menuIndex[i].isActive = false; // 重置一级菜单
                    }
                    this.menuIndex[index].isActive = true;
                    
                    let menu_name = this.menu.button[this.selectedMenuIndex].name;
                    let menu_url = this.menu.button[this.selectedMenuIndex].url;
                    let menu_appid = this.menu.button[this.selectedMenuIndex].appid;
                    let menu_pagepath = this.menu.button[this.selectedMenuIndex].pagepath;
                    let menu_key = this.menu.button[this.selectedMenuIndex].key;
                    let menu_type = this.menu.button[this.selectedMenuIndex].type;
                    this.getInfo(menu_name, menu_url, menu_appid, menu_pagepath, menu_key, menu_type);

                    this.selectedMenuIndex = this.menu.button.length - 1;
                    this.selectedSubMenuIndex = '';
                }
                if (level == 2 && this.menu.button[this.selectedMenuIndex].sub_button.list.length < 5) {
                    if(this.menu.button[this.selectedMenuIndex].sub_button.list.length == 0) {
                        if(this.contIndex == 0 && this.menu.button[this.selectedMenuIndex].key !== '' ) {
                            try {
                                await this.$confirm('添加子菜单后，一级菜单的内容将被清除。确定添加子菜单？', '子菜单确认');
                            } catch {
                                return;
                            }
                        } else if(this.contIndex == 1 && this.menu.button[this.selectedMenuIndex].url !== '') {
                            try {
                                await this.$confirm('添加子菜单后，一级菜单的内容将被清除。确定添加子菜单？', '子菜单确认');
                            } catch {
                                return;
                            }
                        } else if(this.contIndex == 2 && (this.menu.button[this.selectedMenuIndex].appid !== '' || this.menu.button[this.selectedMenuIndex].pagepath !== '')) {
                            try {
                                await this.$confirm('添加子菜单后，一级菜单的内容将被清除。确定添加子菜单？', '子菜单确认');
                            } catch {
                                return;
                            }
                        }
                        if(this.contIndex == 0) {
                            this.menu.button[this.selectedMenuIndex].key = '';
                        } else if(this.contIndex == 1) {
                            this.menu.button[this.selectedMenuIndex].url = '';
                        } else if(this.contIndex == 2) {
                            this.menu.button[this.selectedMenuIndex].appid = '';
                            this.menu.button[this.selectedMenuIndex].pagepath = '';
                        }
                    }
                    
                    this.menu.button[this.selectedMenuIndex].sub_button.list.push({
                        type: 'click',
                        name: '子菜单名称',
                        url: '',
                        appid: '',
                        pagepath: '',
                        key: ''
                    });

                    for ( let i = 0; i < this.menu.button.length; i++ ) {
                        this.subBtnIndex[i].num = -1; // 重置所有子菜单选中状态
                        this.menuIndex[i].isActive = false; // 重置一级菜单
                        this.contIndex = 0;
                        if(i == index) {
                            var sub_button = this.menu.button[i].sub_button.list;
                            if(sub_button.length > 0) {
                                this.subBtnIndex[index].num = sub_button.length - 2;
                                this.menu.button[i].url = '';
                            }
                            this.subBtnIndex[index].num++; // 子菜单选中状态
                        }
                    }

                    this.selectedSubMenuIndex = this.menu.button[this.selectedMenuIndex].sub_button.list.length - 1;

                    let sub_name = this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].name;
                    let sub_url = this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].url;
                    let sub_appid = this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].appid;
                    let sub_pagepath = this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].pagepath;
                    let sub_key = this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].key;
                    let sub_type = this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].type;
                    this.getInfo(sub_name, sub_url, sub_appid, sub_pagepath, sub_key, sub_type);

                    this.urlWindow(index, true);
                    this.operationShow = true;
                }
            },
            // 删除菜单
            async delMenu() {
                if (this.levnlIndex == 1) {
                    try {
                        await this.$confirm('删除后“菜单名”菜单下设置的内容将被删除', '温馨提示');
                    } catch {
                        return;
                    }
                    for ( let i = 0; i < this.menu.button.length; i++ ) {
                        this.subBtnIndex[i].num = -1; // 重置所有子菜单选中状态
                        this.menuIndex[i].isActive = false; // 重置一级菜单
                    }

                    this.operationShow = false;

                    if (this.selectedMenuIndex === 0) {
                        this.menu.button.splice(this.selectedMenuIndex, 1);
                        this.selectedMenuIndex = 0;
                    } else {
                        this.menu.button.splice(this.selectedMenuIndex, 1);
                        this.selectedMenuIndex -= 1;
                    }
                    if (this.menu.button.length == 0) {
                        this.selectedMenuIndex = '';
                    }
                } else if (this.levnlIndex == 2) {
                    try {
                        await this.$confirm('删除后“子菜单”菜单下设置的内容将被删除', '温馨提示');
                    } catch {
                        return;
                    }
                    for ( let i = 0; i < this.menu.button.length; i++ ) {
                        this.subBtnIndex[i].num = -1; // 重置所有子菜单选中状态
                        this.menuIndex[i].isActive = false; // 重置一级菜单
                    }

                    this.operationShow = false;

                    if (this.selectedSubMenuIndex === 0) {
                        this.menu.button[this.selectedMenuIndex].sub_button.list.splice(this.selectedSubMenuIndex, 1);
                        this.selectedSubMenuIndex = 0;
                    } else {
                        this.menu.button[this.selectedMenuIndex].sub_button.list.splice(this.selectedSubMenuIndex, 1);
                        this.selectedSubMenuIndex -= 1;
                    }
                    if (this.menu.button[this.selectedMenuIndex].sub_button.list.length == 0) {
                        this.selectedSubMenuIndex = '';
                        this.menu.button[this.selectedMenuIndex] = {
                            type: 'click',
                            name: this.menu.button[this.selectedMenuIndex].name,
                            url: '',
                            appid: '',
                            pagepath: '',
                            key: '',
                            sub_button: {
                                list: []
                            }
                        }
                    }
                }
            },
            // 保存
            saveItem() {
                var that = this;

                // console.log(this.menu);
                

                that.menu.button.forEach((item, index) => {
                    if(item.sub_button.list.length == 0) {
                        if(item.key == '' && item.type == 'click') {
                            this.selectedMenu(item, index, 1);
                        } else if(item.url == '' && item.type == 'view') {
                            this.selectedMenu(item, index, 1);
                        } else if(item.appid == '' && item.type == 'miniprogram') {
                            this.selectedMenu(item, index, 1);
                        }
                    } else if(item.sub_button.list.length > 0) {
                        item.sub_button.list.forEach((items, indexs) => {
                            if(items.key == '' && items.type == 'click') {
                                this.selectedMenu(item, index, 1);
                                this.loadingSelectedSubMenu(items, index, indexs, 2);
                            } else if(items.url == '' && items.type == 'view') {
                                this.selectedMenu(item, index, 1);
                                this.loadingSelectedSubMenu(items, index, indexs, 2);
                            } else if(items.appid == '' && items.type == 'miniprogram') {
                                this.selectedMenu(item, index, 1);
                                this.loadingSelectedSubMenu(items, index, indexs, 2);
                            }
                        })
                    }
                })

                if(!that.verdict()) { return }
                
                that.disabled = true;
                
                var button = that.menu.button;
                // button.forEach(item => {
                    
                // });

                that.$nextTick(() => {
                    button.forEach(item => {
                        if (item.hasOwnProperty('sub_button')){
                            var sub_button = [];
                            item.sub_button.list.forEach(items => {
                                sub_button.push(items);
                            });
                            that.$set(item, 'sub_button', sub_button);
                        }
                        if(item.type == 'click' && item.sub_button.length == 0 && item.key !== '') {
                            item.key = 'text@@@' + item.key;
                        }
                        item.sub_button.forEach(items => {
                            if(items.type == 'click' && items.key !== '') {
                                items.key = 'text@@@' + items.key;
                            }
                        })
                    })
                })
                
                axios.post(global.wechatURL + "third/set_menu", {
                    appid: that.appid,
                    post_data: {
                        button
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        that.$message({
                            message: '发布成功!',
                            type: 'warning'
                        })
                        that.$router.go(-1);
                    } else {
                        that.$message({ showClose: true, message: '发布失败!', type: 'error'});
                        that.disabled = false;
                    }
                });
            },
            // 判断
            verdict() {
                if(this.levnlIndex == 1 && this.menu.button[this.selectedMenuIndex].sub_button.list.length == 0) {
                    if(this.contIndex == 0 && this.m_key == '') {
                        this.$message({
                            message: '请输入当前菜单文本内容!',
                            type: 'warning'
                        })
                        return false
                    } else if(this.contIndex == 1 && this.m_url == '') {
                        this.$message({
                            message: '请输入当前菜单页面地址!',
                            type: 'warning'
                        })
                        return false
                    } else if(this.contIndex == 2 && (this.m_appid == '' || this.m_pagepath == '' || this.standby_url == '')) {
                        this.$message({
                            message: '请输入当前菜单内容!',
                            type: 'warning'
                        })
                        return false
                    } else {
                        return true;
                    }
                } else if(this.levnlIndex == 2 && this.menu.button[this.selectedMenuIndex].sub_button.list.length > 0) {
                    if(this.contIndex == 0 && this.m_key == '') {
                        this.$message({
                            message: '请输入当前子菜单文本内容!',
                            type: 'warning'
                        })
                        return false
                    } else if(this.contIndex == 1 && this.m_url == '') {
                        this.$message({
                            message: '请输入当前子菜单页面地址!',
                            type: 'warning'
                        })
                        return false
                    } else if(this.contIndex == 2 && (this.m_appid == '' || this.m_pagepath == '' || this.standby_url == '')) {
                        this.$message({
                            message: '请输入当前菜单内容!',
                            type: 'warning'
                        })
                        return false
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            },

            // 取消
            cancel() {
                this.$router.go(-1);
            },
            getInfo(name, url, appid, pagepath, key, type) {
                this.rightTitle = name;
                if(type == 'view') {
                    this.m_url = url;
                    this.standby_url = '';
                } else if(type == 'miniprogram') {
                    this.standby_url = url;
                    this.m_url = '';
                }
                this.m_appid = appid;
                this.m_pagepath = pagepath;
                this.m_key = key;
            },
            rightInput() {
                // this.check(this.rightTitle);
                // let reg = /^([A-z]{1,16}|[\u4e00-\u9fa5]{1,8})$/;
                // if(!reg.test(this.rightTitle)) {
                //     this.warning = true;
                // } else {
                //     this.warning = false;
                // }
            },
            check(str) {
                var  reg = /^[\u4e00-\u9fa5a-z\d_]{2,}$/gi;
                if (reg.test(str)) {
                    var len = str.replace(/[^\x00-\xff]/g, "aa" ).length;
                    if (len < 8 || len > 16) {
                        return this.warning = true;
                    }
                    return this.warning = true;
                }
                return this.warning = false;
            },
            rightBlur() {
                if(this.levnlIndex == 1) {
                    if(this.rightTitle == '') {
                        this.$message({
                            message: '请输入菜单名称!',
                            type: 'warning'
                        })
                        return false
                    }
                    this.menu.button[this.selectedMenuIndex].name = this.rightTitle;
                } else if(this.levnlIndex == 2) {
                    if(this.rightTitle == '') {
                        this.$message({
                            message: '请输入子菜单名称!',
                            type: 'warning'
                        })
                        return false
                    }
                    this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].name = this.rightTitle;
                }
            },
            blurMenu() {
                let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
                if(this.levnlIndex == 1) {
                    if(!reg.test(this.m_url)) {
                        this.$message({
                            message: '请输入正确格式的链接地址',
                            type: 'warning'
                        })
                        return false
                    } else {
                        this.menu.button[this.selectedMenuIndex].url = this.m_url;
                    }
                } else if(this.levnlIndex == 2) {
                    if(!reg.test(this.m_url)) {
                        this.$message({
                            message: '请输入正确格式的链接地址',
                            type: 'warning'
                        })
                        return false
                    } else {
                        this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].url = this.m_url;
                    }
                }
            },
            pagepathBlur() {
                if(this.levnlIndex == 1) {
                    this.menu.button[this.selectedMenuIndex].pagepath = this.m_pagepath;
                    // this.$nextTick(() => {
                    //     this.$refs.standby_url.focus();
                    // })
                } else if(this.levnlIndex == 2) {
                    this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].pagepath = this.m_pagepath;
                    // this.$nextTick(() => {
                    //     this.$refs.standby_url.focus();
                    // })
                }
            },
            standbyUrlBlur() {
                if(this.levnlIndex == 1 && this.menu.button[this.selectedMenuIndex].type == 'miniprogram') {
                    this.menu.button[this.selectedMenuIndex].url = this.standby_url;
                }
                if(this.menu.button[this.selectedMenuIndex].sub_button.list.length > 0) {
                    if(this.levnlIndex == 2 && this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].type == 'miniprogram') {
                        this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].url = this.standby_url;
                    }
                }
            },
            keyBlur() {
                if(!this.m_key) {
                    this.m_key = '';
                }
                if(this.levnlIndex == 1) {
                    this.menu.button[this.selectedMenuIndex].key = this.m_key;
                } else if(this.levnlIndex == 2 && this.menu.button[this.selectedMenuIndex].sub_button.list.length > 0) {
                    this.menu.button[this.selectedMenuIndex].sub_button.list[this.selectedSubMenuIndex].key = this.m_key;
                }
            },
            
            ready(editorInstance) {
                // console.log(`编辑器实例${editorInstance.key}: `, editorInstance);
                this.ue = editorInstance;
                console.log(this.ue)
            },

        }
    }
</script>

<style lang="less" scoped>
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
    .content {
        padding: 0 25px;
    }

    .menu-wrap {
        position: relative;
        margin-top: 35px;
        display: flex;
        align-items: flex-start;
        .menu-left {
            margin-right: 50px;
            width: 300px;
            height: 639px;
            background: #FFFFFF;
            border: 1px solid #BABEC7;
            .menu-title {
                width: 100%;
                height: 64px;
                background: url(../../assets/customize-bg.png) no-repeat;
                background-size: 100% 100%;
                font-size: 16px;
                color: #fff;
                text-align: center;
                line-height: 84px;
            }
            .menu-bot {
                position: absolute;
                bottom: 1px;
                left: 1px;
                width: 300px;
                height: 50px;
                border-top: 1px solid #BABEC7;
                background: url(../../assets/customize-bg01.png) no-repeat;
                background-size: 100% 100%;
                .menu-box {
                    padding-left: 40px;
                    -width: 86.5%;
                    .menu-list {
                        display: flex;
                        align-items: center;
                        color: #252530;
                        .menu-item {
                            flex: 1;
                            position: relative;
                            height: 50px;
                            border-left: 1px solid #BABEC7;
                            font-size: 14px;
                            text-align: center;
                            line-height: 50px;
                            cursor: pointer;
                            .itemName {
                                padding: 0 5px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                word-wrap: normal;
                            }
                            .sub-menu-list-wrap {
                                position: absolute;
                                bottom: 60px;
                                left: 0;
                                right: 0;
                                border: 1px solid #d0d0d0;
                                background: #fafafa;
                            }
                            .sub-menu-list {
                                padding: 0 5px;
                                width: 100%;
                                background-color: #fafafa;
                                box-sizing: border-box;
                                &:hover {
                                    background-color: #ededed;
                                    cursor: pointer;
                                }
                                &:last-child .sub-menu-item {
                                    border: none;
                                }
                                .sub-menu-item {
                                    background-color: #fafafa;
                                    border-bottom: 1px solid #d0d0d0;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    word-wrap: normal;
                                    &:hover {
                                        background-color: #ededed;
                                        cursor: pointer;
                                    }
                                }
                                .current {
                                    background-color: #fff;
                                    color: #07c160;
                                    border: 1px solid #07c160;
                                }
                                .menu-sub-addBtn {
                                    position: relative;
                                    width: 100%;
                                    height: 50px;
                                    text-align: center;
                                    line-height: 50px;
                                    cursor: pointer;
                                    color: #616161;
                                }
                                .menu-arrow {
                                    position: absolute;
                                    left: 50%;
                                    margin-left: -6px;
                                }
                                .arrow_in {
                                    bottom: -4px;
                                    display: inline-block;
                                    width: 0px;
                                    height: 0px;
                                    border-width: 6px 6px 0px;
                                    border-style: solid dashed dashed;
                                    border-color: #fafafa  transparent transparent;
                                }
                                .arrow_out {
                                    bottom: -5px;
                                    display: inline-block;
                                    width: 0px;
                                    height: 0px;
                                    border-width: 6px 6px 0px;
                                    border-style: solid dashed dashed;
                                    border-color: #d0d0d0 transparent transparent;
                                }
                            }
                        }
                        .current {
                            background-color: #fff;
                            color: #07c160;
                            border: 1px solid #07c160;
                            .sub-menu-item {
                                border-bottom-width: 0px !important;
                            }
                        }
                        .menu-addBtn {
                            flex: 1;
                            height: 50px;
                            border-left: 1px solid #BABEC7;
                            font-size: 18px;
                            font-weight: 600;
                            text-align: center;
                            line-height: 50px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .menu-right {

            .menu-detail {
                padding: 0 20px;
                width: 695px;
                height: 639px;
                background: #FFFFFF;
                border: 1px solid #BABEC7;
                .menu-t {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    line-height: 45px;
                    border-bottom: 1px solid #BABEC7;
                    .menu-name {
                        font-size: 14px;
                        color: #252530;
                    }
                    .menu-delBtn {
                        font-size: 14px;
                        color: #576b95;
                        cursor: pointer;
                    }
                }
                .menu-input-group {
                    padding: 20px 0 10px;
                    display: flex;
                    align-items: baseline;
                    .menu-input-name {
                        width: 80px;
                        font-size: 14px;
                        color: #252530;
                    }
                    .menu-input-right {
                        .menu-input {
                            width: 315px;
                        }
                        .menu-inp-title {
                            font-size: 12px;
                            color: #828494;
                            line-height: 30px;
                        }
                    }
                }
                .menu-cont {
                    display: flex;
                    align-items: flex-start;
                    .menu-cont-name {
                        width: 80px;
                        font-size: 14px;
                        color: #252530;
                    }
                    .menu-cont-right {
                        width: 100%;
                    }
                    .choose-box {
                        display: flex;
                        align-items: center;
                        .choose-item {
                            margin-right: 20px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            .choose-icon {
                                margin-right: 10px;
                                width: 20px;
                                height: 20px;
                            }
                            .choose-text {
                                font-size: 14px;
                                font-weight: bold;
                                color: #252530;
                            }
                        }
                    }
                    .cont-wrap {
                        margin: 15px 0 0;
                        .refresh-item {
                            width: 100%;
                        }
                        .refresh-inp {
                            margin-bottom: 10px;
                            /deep/ .el-input-group__prepend {
                                width: 110px;
                            }
                            .refresh-appid {
                                display: flex;
                                align-items: center;
                                /deep/ .el-select {
                                    width: 100%;
                                }
                                /deep/ .el-input__inner {
                                    border-radius: 0 4px 4px 0;
                                }
                                .appid-name {
                                    width: 145px;
                                    height: 38px;
                                    background-color: #F5F7FA;
                                    color: #909399;
                                    font-size: 14px;
                                    line-height: 38px;
                                    vertical-align: middle;
                                    display: table-cell;
                                    position: relative;
                                    border: 1px solid #DCDFE6;
                                    border-radius: 4px 0 0 4px;
                                    padding: 0 20px;
                                    white-space: nowrap;
                                    border-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .btnsItem {
        margin: 35px 0 0 375px;
    }
    .warning {
        font-size: 14px;
        color: #fa5151;
        line-height: 30px;
    }
    .menu-no {
        padding-top: 200px;
        width: 695px;
        text-align: center;
        font-size: 16px;
        color: #9a9a9a;
        line-height: 1.6;
    }
    .ueditor /deep/ .el-textarea {
        width: 100%;
        height: 200px;
        textarea {
            width: 100%;
            height: 100% !important;
        }
    }


</style>
